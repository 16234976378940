import "./styles.scss";
import navigation from "./components/navigation.html";
import hero from "./components/hero.html";
import valueProposition from "./components/valueProposition.html";
import giftOccasion from "./components/giftOccasion.html";
import whatIsIt from "./components/whatIsIt.html";
import digitalItem from "./components/digitalItem.html";
import createSteps from "./components/createSteps.html";
import faq from "./components/faq.html";
import cost from "./components/cost.html";
import footer from "./components/footer.html";

document.getElementById("app").innerHTML = `
${navigation}
${hero}
${valueProposition}
${whatIsIt}
${digitalItem}
${giftOccasion}
${createSteps}
${faq}
${cost}
${footer}
`;



// ${navigation}
// ${hero}
// ${valueProposition}
// ${whatIsIt} - add more images?
// ${digitalItem} - mention how you are going to get the digital item
// ${createSteps}
// ${giftOccasion}
// ${faq}
// ${cost}
// ${footer}

new Swiper('.swiper-what-is-it', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  grabCursor: true,
  cssMode: true,
  freeMode: {
    enabled: true,
    sticky: false,
  },
  mousewheel: {
    forceToAxis: true,
  },
  slidesPerView: "auto",
  spaceBetween: 16,

  breakpoints: {
    320: {
      cssMode: true,
    },
    769: {
      /* The number of slides that will be visible at a time. */
      slidesPerView: "auto",
      spaceBetween: 16,
      cssMode: false,

    }
  }
});

new Swiper('.swiper-hero', {
  slidesPerView: 1,
  clickable: false,
  allowTouchMove: false,
  effect: 'fade',
  fadeEffect: {
    crossFade: true
  },
  speed: 500,
  autoplay: {
    delay: 3500,
  },
});

new Swiper('.swiper-gift-occasion', {
  navigation: {
    nextEl: '.swiper-button-next',
    prevEl: '.swiper-button-prev',
  },

  grabCursor: true,
  freeMode: {
    enabled: true,
    sticky: false,
  },
  mousewheel: {
    forceToAxis: true,
  },

  breakpoints: {
    // when window width is >= 320px
    320: {
      slidesPerView: "auto",
      spaceBetween: 8,
      cssMode: true
    },
    // when window width is >= 480px
    480: {
      slidesPerView: "auto",
      spaceBetween: 8,
      slidesPerGroup: 1,

    },
    // when window width is >= 769px
    769: {
      /* The number of slides that will be visible at a time. */
      slidesPerView: "auto",
      spaceBetween: 16,
      cssMode: false,

    }
  }

});


let skyMapPricePromise = null;

function getSkyMapPrice() {
  // If a request is already in progress, return the existing promise
  if (skyMapPricePromise !== null) {
    console.log('Returning existing promise');
    return skyMapPricePromise;
  }

  // Define API details
  const STRIPE_SERVICE_API_KEY = 'XRosV8WLeL7eN8ll1926J5dyQXinYtlq9GUM0zE1';
  const STRIPE_SERVICE_PRODUCT_ID = 'prod_MuYvrA0anskHIA';
  const STRIPE_SERVICE_ENDPOINT = 'https://d2vozkhok3jdtk.cloudfront.net';
  const STRIPE_GET_SKYMAP_PRICE_ENDPOINT = `${STRIPE_SERVICE_ENDPOINT}/price/SKY_MAP/${STRIPE_SERVICE_PRODUCT_ID}`;

  // Create a new promise for the API request
  skyMapPricePromise = fetch(STRIPE_GET_SKYMAP_PRICE_ENDPOINT, {
    method: 'GET',
    headers: {
      'x-api-key': STRIPE_SERVICE_API_KEY,
    },
  })
      .then(response => {
        if (!response.ok) {
          throw new Error('Network response was not ok');
        }
        return response.json();
      })
      .then(price => {
        console.log(price);
        skyMapPricePromise = null;
        return price;
      })
      .catch(error => {
        console.error('There has been a problem with your fetch operation:', error);
        // Reset promise to allow future requests in case of failure
        skyMapPricePromise = null;
        throw error;
      });

  return skyMapPricePromise;
}

function updatePriceDisplays(price) {
  const priceDisplays = document.querySelectorAll('[data-price-display="true"]');
  priceDisplays.forEach(element => {
    element.textContent = ` - ${price.currencySymbol}${price.amount}`;
  });
}

// Function that fetches the price and updates the UI
function fetchAndDisplayPrice() {
  getSkyMapPrice()
      .then(price => {
        // Assuming 'data' contains a property named 'price' with the value you want to display
        // Adjust the property path as needed based on your actual API response structure
        updatePriceDisplays(price);
      })
      .catch(error => {
        console.error("Failed to fetch price:", error);
        updatePriceDisplays({currencySymbol: '$' , amount: '15'})
      });
}

fetchAndDisplayPrice(); // update the price on page load